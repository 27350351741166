<template>
    <div class="card card-current-application">
        <div class="card-body">
            <div class="row">
                <div class="col-12">
                    <div class="d-flex justify-content-between px-md-1">
                        <div class="align-self-center">
                            <img src="@/assets/logo/logo-apki-1.png" class="card-current-application-icon" height="100" />
                            <!--<i class="fas fa-pencil-alt text-info fa-3x"></i>-->
                        </div>
                        <div class="text-end">
                            <div class="align-self-center">
                                <h3>{{integrationData.version}}</h3>
                                <p class="mb-0">{{integrationData.created}}</p>
                                <p class="mb-0">Najnowsza aplikacja</p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="text-center">
                        <button class="btn btn-primary text" @click="downloadFile(integrationData.file.id, integrationData.file.name)"  data-mdb-toggle="modal" data-mdb-target="#filePrepared">
                            Pobierz
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <BoxLoader v-if="loading.fetch" />
    </div>

    <!-- Modal -->
    <div class="modal fade" id="filePrepared" tabindex="-1" aria-labelledby="filePreparedLabel" aria-hidden="true">
        <div class="modal-dialog">
            <form method="POST" class="" @submit.prevent="filePreparedLabel">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="editChangeLabel">Przygotowywanie pliku</h5>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-10">
                                <div class="progress" style="height: 20px;">
                                    <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" :style="{width: downloadPercent + '%'}" aria-valuenow="{{downloadPercent}}" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </div>
                            <div class="col-2">
                                <div class="text-center">
                                    {{downloadPercent}}%
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="text-center">
                                    {{formatBytes(fileProces.loaded)}} / {{formatBytes(fileProces.total)}}
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-mdb-dismiss="modal" style="display: none" id="filePreparedClosButton">Zamknij</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script charset="utf-8">
    //import IntegrationService from "@/services/integration/integration.service";
    import BoxLoader from "@/components/common/BoxLoader.vue"
    import IntegrationService from "@/services/integration/integration.service"
    import ResourceService from "@/services/resource/resource.service";
    import ConverterHelper from "@/helpers/converter.helper";
    export default {
        components: {
            BoxLoader
        },
        data() {
            return {
                publicPath: process.env.BASE_URL,

                integrationData: {
                    version: "",
                    checksum: "",
                    created: "",
                    updated: "",
                    changes: [],
                    file: {
                        absolutePath: "",
                        description: "",
                        id: null,
                        name: "",
                        relativePath: "",
                        size: -1,
                        type: {
                            id: -1,
                            name: "",
                            description: "",
                        },
                    },
                },

                fileProces: {
                    loaded: 0,
                    total: 0,
                },
                downloadPercent: 0,

                loading: {
                    fetch: false
                },
            };
        },
        computed: {

        },
        mounted() {
            this.fetchNewestApplication();
        },
        methods: {

            async fetchNewestApplication() {
                try {
                    this.loading.fetch = true;
                    let res = await IntegrationService.GetNewestRelease();

                    if (res.status == 200) {
                        this.integrationData = res.data;
                    }

                }
                catch (e) {
                    console.log(e)
                }
                finally {
                    this.loading.fetch = false;
                }
            },
            async downloadFile(resId, resName) {
                try {
                    this.loading.addFile = true;

                    let res = await ResourceService.Download(resId, this.onDowloadProgres);

                    if (res.status == 200) {
                        var fileURL = window.URL.createObjectURL(new Blob([res.data]));
                        var fileLink = document.createElement('a');

                        fileLink.href = fileURL;
                        fileLink.setAttribute('download', resName);
                        document.body.appendChild(fileLink);

                        fileLink.click();
                        document.getElementById("filePreparedClosButton").click();
                    }
                }
                catch (e) {
                    console.log(e);
                }
                finally {
                    this.loading.addFile = false;
                }
            },

            formatBytes(bytes) {
                return ConverterHelper.FormatBytes(bytes);
            },

            onDowloadProgres(percent, loaded, total) {
                this.downloadPercent = percent;
                this.fileProces.loaded = loaded;
                this.fileProces.total = total;
            },
        }
    }
</script>
<style type="text/css">
    .card-current-application {
        transition: all 0.5s ease;
    }

    .card-current-application:hover {
            box-shadow: 0 10px 150px -3px rgb(0 0 0 / 20%), 0 4px 6px -2px rgb(0 0 0 / 10%);
        }
    .card-current-application .card-current-application-icon {
        -webkit-filter: grayscale(100%);
        -moz-filter: grayscale(100%);
        filter: grayscale(100%);
        transition: all 0.5s ease;
    }
    .card-current-application:hover .card-current-application-icon {
        -webkit-filter: grayscale(0%);
        -moz-filter: grayscale(0%);
        filter: grayscale(0%);
    }
</style>