<template>
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header">
                    <h2 class="page-title">Lista</h2>
                </div>
                <div class="card-body card-table">
                    <div class="row p-3">
                        <div class="col-12">
                            <div class="d-flex align-items-end justify-content-end">
                                <div>
                                    <button class="btn btn-primary" data-mdb-toggle="modal" data-mdb-target="#addRelease">Dodaj wersję</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="table-responsive">
                        <table class="table table-hover table-striped">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Wersja</th>
                                    <th>Data dodania</th>
                                    <th>Ostatnia zmiana</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index) in integrationList" :key="index">
                                    <td>{{ (index+1) }}</td>
                                    <td>{{item.version}}</td>
                                    <td>{{item.created}}</td>
                                    <td>{{item.updated}}</td>
                                    <td class="d-flex align-items-end justify-content-end">
                                        <div class="btn-group" role="group">
                                            <router-link :to="{name: 'IntegrationDetails', params:{id: item.id}}" type="button" class="btn btn-light" role="button"><i class="fas fa-list me-2"></i> <span>Szczegóły</span></router-link>
                                            <button type="button" class="btn btn-danger" role="button" @click="deleteRelease(item.id)"><i class="fas fa-trash me-2"></i> <span>Usuń</span></button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <BoxLoader v-if="loading.fetch" />
            </div>
        </div>
    </div>

    <!-- Modal -->
    <div class="modal fade" id="addRelease" tabindex="-1" aria-labelledby="addReleaseLabel" aria-hidden="true">
        <div class="modal-dialog">
            <form method="POST" class="" @submit.prevent="addRelease">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="addReleaseLabel">Dodaj wydanie</h5>
                        <button type="button" class="btn-close" data-mdb-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <ErrorResponse :message="intergrationAddError.message" v-if="intergrationAddError.isError" />
                        <!-- Email input -->
                        <div class="form-group mt-2">
                            <div class="form-outline">
                                <input type="text" id="formVersion" class="form-control form-control-lg custom-form-control" v-model="integrationAdd.Version" :disabled="loading.add" />
                                <label class="form-label custom-form-label" for="formVersion">Wersja</label>
                            </div>
                            <!--<textarea class="form-control" id="textAreaChangeDescription" rows="3" v-model="changeModel.Change"></textarea>-->

                        </div>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-mdb-dismiss="modal" id="close-add-release-modal">Zamknij</button>
                        <button type="submit" class="btn btn-primary">Dodaj</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script charset="utf-8">
    import IntegrationService from "@/services/integration/integration.service";
    import BoxLoader from "@/components/common/BoxLoader.vue" 
    import ErrorResponse from "@/components/common/ErrorResponse.vue"
    export default {
        components: {
            BoxLoader,
            ErrorResponse
        }, 
        data() {
            return {
                publicPath: process.env.BASE_URL,
                integrationList: [],

                integrationAdd: {
                    Version: "",
                },
                loading: {
                    fetch: false,
                    add: false,
                },

                intergrationAddError: {
                    isError: false,
                    message: "",
                }
            };
        },
        computed: {

        },
        mounted() {
            this.fetchAll();
        },
        methods: {

            async fetchAll() {
                try {
                    this.loading.fetch = true;
                    let res = await IntegrationService.GetAllReleasesWithChanges();

                    if (res.status == 200)
                        this.integrationList = res.data.items
                }
                catch (e) {
                    console.log(e);
                }
                finally {
                    this.loading.fetch = false;
                }
            },

            async addRelease() {
                try {
                    this.loading.add = true;
                    this.intergrationAddError.isError = false;
                    this.intergrationAddError.message = "";
                    let res = await IntegrationService.AddRelease(this.integrationAdd);

                    if (res.status == 200) {
                        this.$toast.success("Poprawnie dodano wydanie integracji");
                        var el = document.getElementById("close-add-release-modal");
                        el.click();
                        this.$router.push(
                            {
                                name: 'IntegrationDetails',
                                params: {
                                    id: res.data,
                                }
                            })
                    }
                }
                catch (e) {
                    if (e.response.status == 400) {
                        this.intergrationAddError.isError = true;
                        this.intergrationAddError.message = e.response.data.message;

                    }
                    console.log(e);
                }
                finally {
                    this.loading.add = false;
                }
            },

            async deleteRelease(releaseId) {
                try {
                    this.loading.fetch = true;
                    let res = await IntegrationService.DeleteRelease(releaseId);

                    if (res.status == 200)
                        this.$toast.success("Poprawnie usunięto wydanie integracji");
                        this.integrationList = res.data.items
                }
                catch (e) {
                    console.log(e);
                }
                finally {
                    this.loading.fetch = false;
                }
            }
        }
    }
</script>


