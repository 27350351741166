<template>
    <div class="row">
        <div col="12">
            <div class="card">
                <div class="card-header">
                    <h2 class="page-title">Dostępne uruchomienia aplikacji</h2>
                </div>
                <div class="card-body card-table">
                    <div class="table-responsive">
                        <table class="table table-hover table-striped">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Nazwa</th>
                                    <th>Opis</th>
                                    <th>Token</th>
                                    <th>Kid</th>
                                    <th>Rid</th>
                                    <th>IpAddress</th>
                                    <td>Data dodania</td>
                                    <td></td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index) in eventRunList" :key="index" class="align-middle">
                                    <td>{{(index + 1)}}</td>

                                    <td v-if="item.id === currentEditId"><input type="text" class="form-control" v-model="currentEditModel.Name" @keyup.enter="saveItemChange"/></td>
                                    <td @dblclick="setCurrentEditId(item.id)" v-else>{{item.name}}</td>

                                    <td v-if="item.id === currentEditId"><input type="text" class="form-control" v-model="currentEditModel.Description" @keyup.enter="saveItemChange" autofocus/></td>
                                    <td @dblclick="setCurrentEditId(item.id)"  v-else >{{item.description}}</td>
                                    <td>{{item.token}}</td>
                                    <td>{{item.kid}}</td>
                                    <td>{{item.rid}}</td>
                                    <td>{{item.humanLocalIp}}</td>
                                    <td>{{item.created}}</td>
                                    <td>
                                        <div class="d-flex align-items-end justify-content-end">
                                            <div class="btn-group" role="group">
                                                <router-link :to="{name: 'AppAnalyzerDetail',params:{eventRunId: item.id}}" type="button" class="btn btn-light" role="button"><i class="fas fa-list me-2"></i> <span>Analizuj</span></router-link>

                                                <button v-if="item.id === currentEditId" type="button" class="btn btn-light" role="button" @click="saveItemChange"><i class="fas fa-floppy-disk me-2"></i> <span>Zapisz</span></button>
                                                <button v-else type="button" class="btn btn-light" role="button" @click="setCurrentEditId(item.id)"><i class="fas fa-pen-to-square me-2"></i> <span>Edytuj</span></button>

                                                <button type="button" class="btn btn-danger" role="button"><i class="fas fa-trash me-2"></i> <span>Usuń</span></button>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script charset="utf-8">
    import EventRunService from "@/services/applogs/eventrun.service"
    export default {
        components: {
        },
        data() {
            return {
                publicPath: process.env.BASE_URL,
                eventRunList: [],
                currentEditId: "",
                currentEditModel:{
                    Id: "",
                    Name: "",
                    Description: "",
                },
                loading:{
                    allData:false,
                    saveItem:false,
                }
            };
        },
        computed: {

        },

        mounted(){
            this.fetchEvents();
        },
        methods: {
            async fetchEvents(){

                try{
                    this.loading.allData = true;
                    let res = await EventRunService.GetAll();
                    if(res.status == 200){
                        this.eventRunList = res.data;
                        console.log(res.data);
                    }

                }
                catch(e){
                    console.log(e);

                }
                finally{
                    this.loading.allData = false;

                }
            },

            async saveItemChange(){
                try{
                    this.loading.saveItem = true;
                    let res = await EventRunService.Update(this.currentEditModel.Id, this.currentEditModel);
                    if(res.status == 200){

                        this.$toast.success("Poprawnie dodano opis uruchomienia aplikacji");
                        let indexOfData =  this.eventRunList.map(object => object.id).indexOf(res.data.id);
                        console.log(indexOfData);

                        if(indexOfData == -1)
                            return;

                        this.eventRunList[indexOfData] = res.data;

                        this.currentEditId = "abc";


                    }

                }
                catch(e){
                    console.log(e);

                }
                finally{
                    this.loading.saveItem = false;

                }

            },
            setCurrentEditId(id){
                this.currentEditId = id;
                this.currentEditModel.Id = id;
                let currentItem = this.eventRunList.filter(x=> x.id === id);
                this.currentEditModel.Name = currentItem[0].name;
                this.currentEditModel.Description = currentItem[0].description;

            }
        }
    }

</script>


