const url = {
    auth: {
        login: "Auth/UserAuth"
    },
    appLogs: {
        settings: {
            getAll: "app-logs/settings",
            getById: "app-logs/settings",
            getByEventId: "app-logs/settings/event-run",
            getNewestBeEventId: "app-logs/settings/event-run/EVENT_ID/newest",
            getByToken: "AppLogs/GetSettingsByToken",
            getNewestByToken: "AppLogs/GetNewestSettingByToken",
        },
        device: {
            getHandshakeByToken: "AppLogs/GetHandshakeByToken",
        },
        eventRun: {
            getAll: "event-run",
            getAllByToken: "event-run/by-token",
            getById: "event-run",
            update: "event-run",
            delete: "event-rune",
        }
    },
    integration: {
        getNewestRelease: "Integration/GetNewestRelease",
        getAllReleases: "Integration/GetAllReleases",
        getAllReleasesWithChanges: "Integration/GetAllReleasesWithChanges",
        getReleaseById: "Integration/GetReleaseById",
        getReleaseByIdWithChanges: "Integration/GetReleaseByIdWithChanges",
        addChange: "Integration/AddChange",
        editChange: "Integration/EditChange",
        removeChange: "Integration/DeleteChange",
        addRelease: "Integration/AddRelease",
        deleteRelease: "Integration/DeleteRelease",
        addFile: "Integration/AddFile",
        removeFile: "Integration/RemoveFile",
    },
    resource: {
        download: "Resource/Download"
    }
};

export default url;