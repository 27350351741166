<template>
    <section>
        <div class="row">
            <div class="col-xl-6 col-sm-12 col-12 mb-4">
                <CurrentApplication />
            </div>
        </div>
    </section>
</template>

<script charset="utf-8">
    import CurrentApplication from "@/components/dashboard/CurrentApplication";

    export default {
        components: {
            CurrentApplication
        },
        data() {
            return {
                publicPath: process.env.BASE_URL,


            };
        },
        computed: {

        },
        methods: {


        }
    }
</script>
<style type="text/css">
  
</style>