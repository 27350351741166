<template>
    <div class="row">
        <div class="col-5">
            <div class="card">
                <div class="card-header">
                    <h2 class="page-title">Szczegóły</h2>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-12">
                            <p class="mb-0">Wersja</p>
                            <p class="fs-5 fw-bold">{{integrationData.version}}</p>
                        </div>
                        <div class="col-12">
                            <p class="mb-0">Data dodania</p>
                            <p class="fs-5 fw-bold">{{integrationData.created}}</p>
                        </div>
                        <div class="col-12">
                            <p class="mb-0">Ostatnia aktualizacja</p>
                            <p class="fs-5 fw-bold">{{integrationData.updated}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <BoxLoader v-if="loading.fetch" />
        </div>
        <div class="col-7">
            <div class="card">
                <div class="card-header">
                    <h2 class="page-title">Zmiany</h2>
                </div>
                <div class="card-body card-table">
                    <div class="row p-3">
                        <div class="col-12">
                            <div class="d-flex align-items-end justify-content-end">
                                <div>
                                    <button class="btn btn-primary" data-mdb-toggle="modal" data-mdb-target="#addChange">Dodaj zmianę</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="table-responsive" v-if="integrationData.changes.length > 0">
                        <table class="table table-hover table-striped">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Zmiana</th>
                                    <th>Data dodania</th>
                                    <th>Ostatnia zmiana</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index) in integrationData.changes" :key="index" class="align-middle">
                                    <td>{{ (index+1) }}</td>
                                    <td>{{item.change}}</td>
                                    <td>{{item.created}}</td>
                                    <td>{{item.updated}}</td>
                                    <td>
                                        <div class="d-flex align-items-end justify-content-end">
                                            <div class="btn-group" role="group">
                                                <button type="button" class="btn btn-light" role="button" data-mdb-toggle="modal" data-mdb-target="#editChange" @click="setItem(item)"><i class="fas fa-pen-to-square me-2"></i> <span>Edytuj</span></button>
                                                <button type="button" class="btn btn-danger" role="button" @click="removeChange(item.id)"><i class="fas fa-trash me-2"></i><span>Usuń</span></button>
                                            </div>
                                        </div>

                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <BoxLoader v-if="loading.fetch" />
        </div>
        <div class="col-7 mt-3">
            <div class="card">
                <div class="card-header">
                    <h2 class="page-title">Plik</h2>
                </div>
                <div class="card-body card-table">
                    <div class="row p-3" v-if="integrationData.file.id == null">
                        <div class="col-12">
                            <div class="d-flex align-items-end justify-content-end">
                                <div>
                                    <button class="btn btn-primary" data-mdb-toggle="modal" data-mdb-target="#addFile">Dodaj plik</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="table-responsive" v-else>
                        <table class="table table-hover table-striped">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Plik</th>
                                    <th>Suma kontrolna</th>
                                    <th>Rozmiar</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="align-middle">
                                    <td>1</td>
                                    <td><a href="javascript:void(0)" @click="downloadFile(integrationData.file.id, integrationData.file.name)"  data-mdb-toggle="modal" data-mdb-target="#filePrepared" >{{integrationData.file.name}}</a></td>
                                    <td>{{integrationData.file.checksum}}</td>
                                    <td>{{formatBytes(integrationData.file.size)}}</td>
                                    <td class="d-flex align-items-end justify-content-end">
                                        <div class="btn-group" role="group">
                                            <button type="button" class="btn btn-danger" role="button" @click="removeFile(integrationData.file.id)"><i class="fas fa-trash me-2"></i><span>Usuń</span></button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <!-- Modal -->
    <div class="modal fade" id="addChange" tabindex="-1" aria-labelledby="addChangeLabel" aria-hidden="true">
        <div class="modal-dialog">
            <form method="POST" class="" @submit.prevent="addChange">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="addChangeLabel">Dodaj zmianę</h5>
                        <button type="button" class="btn-close" data-mdb-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">

                        <!-- Email input -->
                        <div class="form-group green-border-focus">
                            <label for="textAreaChangeDescription">Opis zmian</label>
                            <textarea class="form-control" id="textAreaChangeDescription" rows="3" v-model="changeModel.Change"></textarea>

                        </div>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-mdb-dismiss="modal">Zamknij</button>
                        <button type="submit" class="btn btn-primary">Dodaj</button>
                    </div>
                </div>
            </form>
        </div>
    </div>

    <!-- Modal -->
    <div class="modal fade" id="editChange" tabindex="-1" aria-labelledby="editChangeLabel" aria-hidden="true">
        <div class="modal-dialog">
            <form method="POST" class="" @submit.prevent="editChange">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="editChangeLabel">Edytuj zmianę</h5>
                        <button type="button" class="btn-close" data-mdb-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="form-group green-border-focus">
                            <label for="textAreaEditChangeDescription">Opis zmian</label>
                            <textarea class="form-control" id="textAreaEditChangeDescription" rows="3" v-model="editChangeModel.Change"></textarea>

                        </div>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-mdb-dismiss="modal">Zamknij</button>
                        <button type="submit" class="btn btn-primary">Zapisz</button>
                    </div>
                </div>
            </form>
        </div>
    </div>

    <!-- Modal -->
    <div class="modal fade" id="addFile" tabindex="-1" aria-labelledby="addFileLabel" aria-hidden="true">
        <div class="modal-dialog">
            <form method="POST" class="" @submit.prevent="addFile">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="addFileLabel">Dodaj plik</h5>
                        <button type="button" class="btn-close" data-mdb-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="form-group green-border-focus mb-2">
                            <label for="labelFile">Plik</label>
                            <input type="file" id="labelFile" class="form-control" ref="fileForm" @change="fileInputChange" />
                            <!--<textarea class="form-control" id="textAreaEditChangeDescription" rows="3" v-model="editChangeModel.Change"></textarea>-->

                        </div>
                        <div class="form-group green-border-focus mb-2">
                            <label for="labelFileDescription">Plik</label>
                            <input type="text" id="labelFileDescription" class="form-control" v-model="uploadFileModel.Description" />
                            <!--<textarea class="form-control" id="textAreaEditChangeDescription" rows="3" v-model="editChangeModel.Change"></textarea>-->

                        </div>
                        <div class="row">
                            <div class="col-10">
                                <div class="progress" style="height: 20px;">
                                    <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" :style="{width: uploadPercent + '%'}" aria-valuenow="{{uploadPercent}}" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </div>
                            <div class="col-2">
                                <div class="text-center">
                                    {{uploadPercent}}%
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="text-center">
                                    {{formatBytes(uploadProces.loaded)}} / {{formatBytes(uploadProces.total)}}
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-mdb-dismiss="modal" id="addFileModalClose">Zamknij</button>
                        <button type="submit" class="btn btn-primary">Zapisz</button>
                    </div>
                </div>
            </form>
        </div>
    </div>


    <!-- Modal -->
    <div class="modal fade" id="filePrepared" tabindex="-1" aria-labelledby="filePreparedLabel" aria-hidden="true">
        <div class="modal-dialog">
            <form method="POST" class="" @submit.prevent="filePreparedLabel">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="editChangeLabel">Przygotowywanie pliku</h5>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-10">
                                <div class="progress" style="height: 20px;">
                                    <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" :style="{width: downloadPercent + '%'}" aria-valuenow="{{downloadPercent}}" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </div>
                            <div class="col-2">
                                {{downloadPercent}}%
                            </div>
                        </div>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-mdb-dismiss="modal" style="display: none" id="filePreparedClosButton">Zamknij</button>
                    </div>
                </div>
            </form>
        </div>
    </div>

</template>

<script charset="utf-8">

    import IntegrationService from "@/services/integration/integration.service";
    import ResourceService from "@/services/resource/resource.service";
    import BoxLoader from "@/components/common/BoxLoader.vue" 


    export default {
        components: {
            BoxLoader
        },
        data() {
            return {
                publicPath: process.env.BASE_URL,
                integrationData: {
                    version: "",
                    checksum: "",
                    created: "",
                    updated: "",
                    changes: [],
                    file: {
                        absolutePath:"",
                        description:"",
                        id: null,
                        name: "",
                        relativePath: "",
                        size: -1,
                        type: {
                            id: -1,
                            name: "",
                            description: "",
                        },
                    },
                },

                currentChange: {},

                changeModel: {
                    IntegrationID: 0,
                    Change:"",
                },

                uploadProces: {
                    loaded: 0,
                    total: 0,
                },
                downloadProces: {
                    loaded: 0,
                    total: 0,
                },

                uploadPercent: 0,
                downloadPercent: 0,

                editChangeModel: {
                    Id: 0,
                    IntegrationID: 0,
                    Change: "",
                },

                uploadFileModel: {
                    IntegrationID: -1,
                    File: {},
                    Description: "",
                },
                loading: {
                    fetch: false,
                    addChange: false,
                    editChange: false,
                    deleteChange: false,
                    addFile: false,
                },
            };
        },
        computed: {

        },
        mounted() {
            this.fetchIntegration();
        },
        methods: {

            async fetchIntegration() {
                try {
                    this.loading.fetch = true;
                    let res = await IntegrationService.GetReleaseByIdWithChanges(this.$route.params.id)
                    if (res.status == 200)
                    {
                        this.integrationData = res.data;
                        this.changeModel.IntegrationID = this.integrationData.id;
                        this.uploadFileModel.IntegrationID = this.integrationData.id;
                        this.editChangeModel.IntegrationID = this.integrationData.id;
                    }
                }
                catch (e) {
                    console.log(e)
                }
                finally {
                    this.loading.fetch = false;
                }
            },

            async addChange() {
                try {
                    this.loading.addChange = true;

                    let res = await IntegrationService.AddChange(this.changeModel);

                    if (res.status == 200) {
                        this.$toast.success("Poprawnie dodano opis zmian");
                        this.integrationData.changes = res.data;
                    }
                }
                catch (e) {
                    console.log(e)
                }
                finally {
                    this.loading.addChange = false;
                }
            },

            async editChange() {
                try {
                    this.loading.editChange = true;

                    let res = await IntegrationService.EditChange(this.editChangeModel);

                    if (res.status == 200) {
                        this.$toast.success("Poprawnie zaktualizowano opis zmian");
                        this.integrationData.changes = res.data;
                    }
                }
                catch (e) {
                    console.log(e)
                }
                finally {
                    this.loading.editChange = false;
                }
            },
            setItem(item) {
                this.editChangeModel.Id = item.id;
                this.editChangeModel.Change = item.change;

                this.currentChange = item;
            },

            async removeChange(changeId) {
                try {
                    this.loading.deleteChange = true;
                    let res = await IntegrationService.RemoveChange(changeId);

                    if (res.status == 200) {
                        this.$toast.success("Poprawnie usunięto opis zmian");
                        this.integrationData.changes = res.data;
                    }
                }
                catch (e) {
                    console.log(e)
                }
                finally {
                    this.loading.deleteChange = false;
                }
            },

            async addFile() {

                try {
                    this.loading.addFile = true;
                    this.uploadPercent = 0;

                    let res = await IntegrationService.AddFile(this.uploadFileModel, this.onProgress);
                    if (res.status == 200) {
                        this.$toast.success("Poprawnie dodano plik");
                        this.integrationData.file = res.data;

                        document.getElementById('addFileModalClose').click();
                        this.$refs.fileForm.value = null
                        this.uploadFileModel.Description = "";
                        this.uploadPercent = 0;

                    }

                }
                catch (e) {
                    console.log(e);
                }
                finally {
                    this.loading.addFile = false;
                }
            },

            onProgress(percent, loaded, total) {
                this.uploadPercent = percent;
                this.uploadProces.loaded = loaded;
                this.uploadProces.total = total;

            },
            onDowloadProgres(percent, loaded, total) {
                this.downloadPercent = percent;
                this.downloadProces.loaded = loaded;
                this.downloadProces.total = total;

            },
            fileInputChange() {
                this.uploadFileModel.File = this.$refs.fileForm.files[0];
            },
            formatBytes(bytes, decimals = 2) {
                if (!+bytes) return '0 Bytes'

                const k = 1024
                const dm = decimals < 0 ? 0 : decimals
                const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

                const i = Math.floor(Math.log(bytes) / Math.log(k))

                return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
            },

            async removeFile(resId) {
                try {
                    this.loading.addFile = true;
                    let res = await IntegrationService.RemoveFile(resId);

                    if (res.status == 200) {
                        this.$toast.success("Poprawnie usunięto plik");
                        this.integrationData.file = res.data;
                    }
                }
                catch (e) {
                    this.$toast.error("Wystąpił błąd przy usuwaniu pliku");
                    console.log(e)
                }
                finally {
                    this.loading.addFile = false;
                }
            },

            async downloadFile(resId, resName) {
                try {
                    this.loading.addFile = true;

                    let res = await ResourceService.Download(resId, this.onDowloadProgres);

                    if (res.status == 200) {
                        console.log("Res", res.headers["content-type"]);
                        let contentType = res.headers["content-type"]
                        var fileURL = window.URL.createObjectURL(new Blob([res.data], { contentType }));
                        var fileLink = document.createElement('a');

                        fileLink.href = fileURL;
                        fileLink.setAttribute('download', resName);
                        document.body.appendChild(fileLink);

                        fileLink.click();
                        document.getElementById("filePreparedClosButton").click();
                    }
                }
                catch (e) {
                    console.log(e);
                }
                finally {
                    this.loading.addFile = false;
                }
            },

        }
    }
</script>


