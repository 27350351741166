<template>
    <div class="card">
        <div class="card-header text-center py-3">
            <h5 class="mb-0 text-center">
                <strong>Ustawienia aplikacji</strong>
            </h5>
        </div>

        <i class="fa-solid fa-timeline-arrow"></i>
        <div class="card-body card-table">
            <!-- Tabs navs -->
            <ul class="nav nav-tabs nav-fill mb-3" id="ex-with-icons" role="tablist">
                <li class="nav-item" role="presentation">
                    <a class="nav-link active" id="ex-with-icons-tab-1" data-mdb-toggle="tab" href="#ex-with-icons-tabs-1" role="tab"
                       aria-controls="ex-with-icons-tabs-1" aria-selected="true"><i class="fas fa-cogs fa-fw me-2"></i>Obecne ustawienia</a>
                </li>
                <li class="nav-item" role="presentation">
                    <a class="nav-link" id="ex-with-icons-tab-2" data-mdb-toggle="tab" href="#ex-with-icons-tabs-2" role="tab"
                       aria-controls="ex-with-icons-tabs-2" aria-selected="false"><i class="fa-solid fa-rectangle-history fa-fw me-2"></i>Zapisane podczas tego uruchomienia</a>
                </li>
            </ul>
            <!-- Tabs navs -->
            <!-- Tabs content -->
            <div class="tab-content" id="ex-with-icons-content">
                <div class="tab-pane fade show active" id="ex-with-icons-tabs-1" role="tabpanel" aria-labelledby="ex-with-icons-tab-1">
                    <div class="row mx-4 my-3">
                        <div class="col-12">
                            <div class="row">
                                <div class="col-4">
                                    <p class="mb-0"><strong>ID:</strong></p>
                                    <p>{{currentAppSettings.id}}</p>
                                </div>
                                <div class="col-4">
                                    <p class="mb-0"><strong>appVersion:</strong></p>
                                    <p>{{currentAppSettings.appVersion}}</p>
                                </div>
                                <div class="col-4">
                                    <p class="mb-0"><strong>timestamp:</strong></p>
                                    <p>{{currentAppSettings.timestamp}}</p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-6">
                                    <p class="mb-0"><strong>allowApplicationFromMic:</strong> {{currentAppSettings.setting.allowApplicationFromMic}}</p>
                                    <p class="mb-0"><strong>allowMultiLogin:</strong> {{currentAppSettings.setting.allowMultiLogin}}</p>
                                    <p class="mb-0"><strong>allowResignation:</strong> {{currentAppSettings.setting.allowResignation}}</p>
                                    <p class="mb-0"><strong>appCameraControl:</strong> {{currentAppSettings.setting.appCameraControl}}</p>
                                    <p class="mb-0"><strong>autoSendInterval:</strong> {{currentAppSettings.setting.autoSendInterval}}</p>
                                    <p class="mb-0"><strong>autoSendLogs:</strong> {{currentAppSettings.setting.autoSendLogs}}</p>
                                    <p class="mb-0"><strong>blockChangeVote:</strong> {{currentAppSettings.setting.blockChangeVote}}</p>
                                    <p class="mb-0"><strong>connectedInterface:</strong> {{currentAppSettings.setting.connectedInterface}}</p>
                                    <p class="mb-0"><strong>controlUnitModel:</strong> {{currentAppSettings.setting.controlUnitModel}}</p>
                                    <p class="mb-0"><strong>devicePingInterval:</strong> {{currentAppSettings.setting.devicePingInterval}}</p>
                                    <p class="mb-0"><strong>deviceType:</strong> {{currentAppSettings.setting.deviceType}}</p>
                                    <p class="mb-0"><strong>enableDevicePing:</strong> {{currentAppSettings.setting.enableDevicePing}}</p>
                                    <p class="mb-0"><strong>esesjaURL:</strong> {{currentAppSettings.setting.esesjaURL}}</p>
                                </div>
                                <div class="col-6">
                                    <p class="mb-0"><strong>esesjaWebsocket:</strong> {{currentAppSettings.setting.esesjaWebsocket}}</p>
                                    <p class="mb-0"><strong>extetedMicrophoneControl:</strong> {{currentAppSettings.setting.extetedMicrophoneControl}}</p>
                                    <p class="mb-0"><strong>ipAddress:</strong> {{currentAppSettings.setting.ipAddress}}</p>
                                    <p class="mb-0"><strong>isNFC:</strong> {{currentAppSettings.setting.isNFC}}</p>
                                    <p class="mb-0"><strong>isSsl:</strong> {{currentAppSettings.setting.isSsl}}</p>
                                    <p class="mb-0"><strong>kid:</strong> {{currentAppSettings.setting.kid}}</p>
                                    <p class="mb-0"><strong>logTCPPacket:</strong> {{currentAppSettings.setting.logTCPPacket}}</p>
                                    <p class="mb-0"><strong>rid:</strong> {{currentAppSettings.setting.rid}}</p>
                                    <p class="mb-0"><strong>isSsl:</strong> {{currentAppSettings.setting.isSsl}}</p>
                                    <p class="mb-0"><strong>showVotingResults:</strong> {{currentAppSettings.setting.showVotingResults}}</p>
                                    <p class="mb-0"><strong>token:</strong> {{currentAppSettings.setting.token}}</p>
                                    <p class="mb-0"><strong>vMixAddress:</strong> {{currentAppSettings.setting.vMixAddress}}</p>
                                    <p class="mb-0"><strong>vMixPort:</strong> {{currentAppSettings.setting.vMixPort}}</p>
                                </div>

                            </div>
                        </div>
                        <BoxLoader v-if="loading.currentSetting" />
                    </div>

                </div>
                <div class="tab-pane fade" id="ex-with-icons-tabs-2" role="tabpanel" aria-labelledby="ex-with-icons-tab-2">
                    <div class="row w-100">
                        <div class="col-3">
                            <!-- Tab navs -->
                            <div class="nav flex-column nav-tabs text-center" id="v-tabs-tab" role="tablist" aria-orientation="vertical">

                                <a v-for="(item, index) in allAvailableSettings" :key="index" class="nav-link active" :id="createInternaAnchorId(item.id)" data-mdb-toggle="tab" :href="createInternalHref(item.id)" role="tab" :aria-controls="item.id" aria-selected="false">{{item.timestamp}}</a>

                                <!--<a class="nav-link active" id="v-tabs-home-tab" data-mdb-toggle="tab" href="#v-tabs-home" role="tab" aria-controls="v-tabs-home" aria-selected="true">Home</a>
                                <a class="nav-link"
                                   id="v-tabs-profile-tab"
                                   data-mdb-toggle="tab"
                                   href="#v-tabs-profile"
                                   role="tab"
                                   aria-controls="v-tabs-profile"
                                   aria-selected="false">Profile</a>
                                <a class="nav-link"
                                   id="v-tabs-messages-tab"
                                   data-mdb-toggle="tab"
                                   href="#v-tabs-messages"
                                   role="tab"
                                   aria-controls="v-tabs-messages"
                                   aria-selected="false">Messages</a>-->
                            </div>
                            <!-- Tab navs -->
                        </div>

                        <div class="col-9 my-4">
                            <!-- Tab content -->
                            <div class="tab-content" id="v-tabs-tabContent">
                                <div v-for="(item, index) in allAvailableSettings" :key="index" class="tab-pane fade show active"
                                     :id="createTabId(item.id)"
                                     role="tabpanel"
                                     :aria-labelledby="createInternaAnchorId(item.id)">
                                    <div class="row">
                                        <div class="col-xl-6 col-12">
                                            <p class="mb-0"><strong>allowApplicationFromMic:</strong> {{item.setting.allowApplicationFromMic}}</p>
                                            <p class="mb-0"><strong>allowMultiLogin:</strong> {{item.setting.allowMultiLogin}}</p>
                                            <p class="mb-0"><strong>allowResignation:</strong> {{item.setting.allowResignation}}</p>
                                            <p class="mb-0"><strong>appCameraControl:</strong> {{item.setting.appCameraControl}}</p>
                                            <p class="mb-0"><strong>autoSendInterval:</strong> {{item.setting.autoSendInterval}}</p>
                                            <p class="mb-0"><strong>autoSendLogs:</strong> {{item.setting.autoSendLogs}}</p>
                                            <p class="mb-0"><strong>blockChangeVote:</strong> {{item.setting.blockChangeVote}}</p>
                                            <p class="mb-0"><strong>connectedInterface:</strong> {{item.setting.connectedInterface}}</p>
                                            <p class="mb-0"><strong>controlUnitModel:</strong> {{item.setting.controlUnitModel}}</p>
                                            <p class="mb-0"><strong>devicePingInterval:</strong> {{item.setting.devicePingInterval}}</p>
                                            <p class="mb-0"><strong>deviceType:</strong> {{item.setting.deviceType}}</p>
                                            <p class="mb-0"><strong>enableDevicePing:</strong> {{item.setting.enableDevicePing}}</p>
                                            <p class="mb-0"><strong>esesjaURL:</strong> {{item.setting.esesjaURL}}</p>
                                        </div>
                                        <div class="col-xl-6 col-12">
                                            <p class="mb-0"><strong>esesjaWebsocket:</strong> {{item.setting.esesjaWebsocket}}</p>
                                            <p class="mb-0"><strong>extetedMicrophoneControl:</strong> {{item.setting.extetedMicrophoneControl}}</p>
                                            <p class="mb-0"><strong>ipAddress:</strong> {{item.setting.ipAddress}}</p>
                                            <p class="mb-0"><strong>isNFC:</strong> {{item.setting.isNFC}}</p>
                                            <p class="mb-0"><strong>isSsl:</strong> {{item.setting.isSsl}}</p>
                                            <p class="mb-0"><strong>kid:</strong> {{item.setting.kid}}</p>
                                            <p class="mb-0"><strong>logTCPPacket:</strong> {{item.setting.logTCPPacket}}</p>
                                            <p class="mb-0"><strong>rid:</strong> {{item.setting.rid}}</p>
                                            <p class="mb-0"><strong>isSsl:</strong> {{item.setting.isSsl}}</p>
                                            <p class="mb-0"><strong>showVotingResults:</strong> {{item.setting.showVotingResults}}</p>
                                            <p class="mb-0"><strong>token:</strong> {{item.setting.token}}</p>
                                            <p class="mb-0"><strong>vMixAddress:</strong> {{item.setting.vMixAddress}}</p>
                                            <p class="mb-0"><strong>vMixPort:</strong> {{item.setting.vMixPort}}</p>
                                        </div>

                                    </div>
                                </div>
                                <!--<div class="tab-pane fade"
                                     id="v-tabs-profile"
                                     role="tabpanel"
                                     aria-labelledby="v-tabs-profile-tab">
                                    Profile content
                                </div>
                                <div class="tab-pane fade"
                                     id="v-tabs-messages"
                                     role="tabpanel"
                                     aria-labelledby="v-tabs-messages-tab">
                                    Messages content
                                </div>-->
                            </div>
                            <!-- Tab content -->
                        </div>
                    </div>
                </div>
            </div>
            <!-- Tabs content -->
            
        </div>
    </div>
</template>

<script charset="utf-8">
    import AppLogsSettingsService from "@/services/applogs/applogs.settings.service.js";
    import BoxLoader from "@/components/common/BoxLoader.vue" 
    export default {
        components: {
            BoxLoader
        },
        props: {
            eventId: {
                type: String,
                require: true,
            }
        },
        data() {
            return {
                publicPath: process.env.BASE_URL,

                currentAppSettings: {
                    id: "",
                    appVersion: "",
                    timestamp: "2023-01-10T10:59:49.12Z",
                    setting: {
                        allowApplicationFromMic: false,
                        allowMultiLogin: false,
                        allowResignation: false,
                        appCameraControl: false,
                        autoSendInterval: "00:01:00",
                        autoSendLogs: false,
                        blockChangeVote: false,
                        connectedInterface: 0,
                        controlUnitModel: 0,
                        devicePingInterval: "00:00:05",
                        deviceType: 0,
                        enableDevicePing: false,
                        esesjaURL: "",
                        esesjaWebsocket: "",
                        extetedMicrophoneControl: true,
                        ipAddress: "x.x.x.x",
                        isNFC: false,
                        isSsl: false,
                        kid: 0,
                        logTCPPacket: false,
                        rid: 0,
                        showVotingResults: false,
                        token: "xxxxxx",
                        vMixAddress: "xxx.xxx.xxx.xxx",
                        vMixPort: 0,
                    },


                },
                allAvailableSettings: [],

                loading: {
                    currentSetting: false,
                    allSettings: false,
                }
            };
        },

        watch: {
            eventId: {
                immediate: true,
                handler(val) {
                    this.getCurrentClientAppSetting(val)
                    this.fetchAllAppSettings(val)
                }
            }
        },
        computed: {

        },
        methods: {

            async getCurrentClientAppSetting(eventId){
                try {
                    this.loading.currentSetting = true;
                    let res = await AppLogsSettingsService.GetNewestByEventId(eventId)
                    if (res.status == 200) {
                        this.currentAppSettings = res.data;
                    }
                }
                catch (e) {
                    //if (e.hasOwnProperty("response")) {
                    //    this.$toast.error(e.response.data.message)
                    //}
                    console.log(e);
                }
                finally {
                    this.loading.currentSetting = false;
                }
            },

            async fetchAllAppSettings(eventId) {
                try {
                    this.loading.allSettings = true;
                    let res = await AppLogsSettingsService.GetByEventId(eventId)
                    if (res.status == 200) {
                        this.allAvailableSettings = res.data;
                    }
                }
                catch (e) {
                    console.log(e);
                }
                finally {
                    this.loading.allSettings = false;
                }
            },

            createInternaAnchorId(anchor) {
                return `${anchor}-tab`
            },

            createInternalHref(anchor) {
                return `#${this.createTabId(anchor)}`
            },

            createTabId(anchor) {
                return `tab-${anchor}`
            }
        }
    }
</script>


