import url from "@/services/common/url";
import api from "@/services/common/api";

class ResourceService {
    Download(id, onProgress) {
        let config = {
            responseType: 'blob',
            onDownloadProgress: progressEvent => {
                let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                if (onProgress) onProgress(percentCompleted, progressEvent.loaded, progressEvent.total);
            }
        }
        return api.get(url.resource.download + "/" + id, config)
            .then((res) => { return res });
    }
}

export default new ResourceService();